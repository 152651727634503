export const baseUrl =
  process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL ?? 'localhost:3000'

export const cockpitUrl = process.env.NEXT_PUBLIC_COCKPIT_URL ?? ''

export const siteName = process.env.SITE_NAME ?? ''

export const isProd = process.env.NODE_ENV === 'production'

export const isDev = process.env.NODE_ENV === 'development'

export const isBrowser = typeof window !== 'undefined'

export const gtmId = process.env.NEXT_PUBLIC_GTM_ID ?? ''

export const gaId = process.env.NEXT_PUBLIC_GA_ID ?? ''

export const googleMapsKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS ?? ''

export const meilisearch = {
  host: process.env.NEXT_PUBLIC_MEILISEARCH_HOST ?? '',
  publicKey: process.env.NEXT_PUBLIC_MEILISEARCH_KEY ?? '',
  privateKey: process.env.MEILISEARCH_KEY ?? ''
}
