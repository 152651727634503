import { useSkeleton, useSkeletonProps } from './use-skeleton'

export type SkeletonProps = useSkeletonProps

const Skeleton = (props: SkeletonProps) => {
  const { className, children, styles, componentProps } = useSkeleton(props)

  return (
    <span className={styles.base({ className })} {...componentProps}>
      <span className={styles.content()}>{children}</span>
    </span>
  )
}

export default Skeleton
