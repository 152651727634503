'use client'

import NextImage from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y, EffectFade } from 'swiper/modules'
import { NextLink } from '@/utils/navigation'
import { GalleryNav } from './gallery.nav'
import { useGallery, useGalleryProps } from './use-gallery'

export type GalleryProps = useGalleryProps

const Gallery = (props: GalleryProps) => {
  const { styles, className, galleryId, href, target, data } = useGallery(props)

  return (
    <div className={styles.base()}>
      <Swiper
        className={styles.gallery({ className })}
        modules={[Navigation, Pagination, A11y, EffectFade]}
        simulateTouch={false}
        slidesPerView={1}
        style={{
          // @ts-ignore
          '--swiper-pagination-color': '#fff'
        }}
        pagination={{
          clickable: false
        }}
        navigation={{
          prevEl: `.prev-gallery-${galleryId}`,
          nextEl: `.next-gallery-${galleryId}`
        }}
      >
        {data.map(({ id, image }) => (
          <SwiperSlide
            key={`gallery-${id}`}
            className={styles.item({ className })}
          >
            <NextImage
              className={styles.cover()}
              src={image}
              alt={id}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </SwiperSlide>
        ))}
        <GalleryNav styles={styles} galleryId={galleryId} />
        {!!href && (
          <NextLink
            href={href}
            target={target}
            className={styles.link()}
            prefetch={false}
          />
        )}
      </Swiper>
    </div>
  )
}

export default Gallery
