import { useContext } from 'react'
import { ConversionContext } from '@/contexts/conversion'
import { Property } from '@/types'

export const useConversion = () => {
  const { state, setState } = useContext(ConversionContext)

  const openForm = (reference: Property['reference']) => {
    setState({ reference, opened: true })
  }

  const closeForm = () => {
    setState({ reference: null, opened: false })
  }

  return {
    ...state,
    openForm,
    closeForm
  }
}
