import { VariantProps, tv } from '@/utils/tv'

export const gallery = tv({
  slots: {
    base: ['group', 'w-full h-full', 'relative', 'overflow-hidden'],
    link: ['absolute inset-0 z-20', 'm-auto', 'w-full h-full'],
    gallery: ['relative z-20', 'w-full h-full'],
    item: [
      'absolute',
      'w-full h-full',
      'min-w-full max-w-full',
      'aspect-w-16 aspect-h-10',
      'bg-gray-50'
    ],
    cover: ['object-cover object-center'],
    arrow: ['absolute z-30 inset-y-0', 'my-auto', 'shadow-md']
  }
})

export type GalleryVariantProps = VariantProps<typeof gallery>

export type GalleryReturnVariantProps = ReturnType<typeof gallery>
