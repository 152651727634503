'use client'

import { ButtonGroup } from '@/components/common/button-group'
import { getWhatsAppLink } from '@/utils/whatsapp'
import { useConversion } from '@/hooks/use-conversion'
import { Skeleton } from '@/components/ui/skeleton'
import { PropertyCardProps } from './property-card'

export type PropertyCardActionsProps = {
  lang: Record<string, string>
  reference: PropertyCardProps['data']['reference']
  isSkeleton?: boolean
}

export const PropertyCardActions = ({
  lang,
  reference,
  isSkeleton
}: PropertyCardActionsProps) => {
  const { openForm } = useConversion()

  if (isSkeleton) {
    return (
      <div className="flex gap-2">
        <Skeleton isLoaded={!isSkeleton} className="block h-10 w-40" />
        <Skeleton isLoaded={!isSkeleton} className="block h-10 w-10" />
      </div>
    )
  }

  return (
    <ButtonGroup
      buttons={[
        {
          className: 'grow w-auto',
          color: 'primary',
          fullWidth: true,
          size: 'lg',
          children: lang.want_property,
          onPress: () => openForm(reference)
        },
        {
          color: 'primary',
          variant: 'outline',
          size: 'lg',
          startIcon: 'social-whatsapp',
          target: '_blank',
          href: getWhatsAppLink({ message: 'property', params: { reference } })
        }
      ]}
    />
  )
}
