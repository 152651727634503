import { Button } from '@/components/ui/button'
import { GalleryReturnVariantProps } from './gallery.variants'

type GalleryNavProps = {
  styles: GalleryReturnVariantProps
  galleryId: string
}

export const GalleryNav = (props: GalleryNavProps) => {
  const { styles, galleryId } = props

  return (
    <>
      <Button
        className={styles.arrow({
          className: `left-3 prev-gallery-${galleryId}`
        })}
        color="light"
        size="xs"
        startIcon="chevron-left"
        rounded
      />
      <Button
        className={styles.arrow({
          className: `right-3 next-gallery-${galleryId}`
        })}
        color="light"
        size="xs"
        startIcon="chevron-right"
        rounded
      />
    </>
  )
}

export default GalleryNav
