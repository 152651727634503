import { useMemo } from 'react'
import { SkeletonVariantProps, skeleton } from './skeleton.variants'

export type useSkeletonProps = SkeletonVariantProps & {
  className?: string
  children?: React.ReactNode
  isLoaded?: boolean
}

export const useSkeleton = (props: useSkeletonProps) => {
  const {
    className = '',
    children,
    rounded = 'sm',
    isLoaded = false,
    ...componentProps
  } = props

  const styles = useMemo(
    () => skeleton({ rounded, isLoaded }),
    [rounded, isLoaded]
  )

  return {
    className,
    children,
    styles,
    componentProps
  }
}
