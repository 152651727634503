import { useEffect, useMemo, useState } from 'react'
import { ToggleButtonProps as AriaToggleButtonProps } from 'react-aria-components'
import { FavoriteAction } from '@/contexts/favorites'
import {
  FavoriteToggleVariantProps,
  favoriteToggle
} from './favorite-toggle.variants'
import { useFavorites } from '@/hooks/use-favorites'

export type useFavoriteToggleProps = FavoriteToggleVariantProps & {
  className?: string
  reference: string
} & Omit<AriaToggleButtonProps, 'className'>

export const useFavoriteToggle = (props: useFavoriteToggleProps) => {
  const {
    className = '',
    variant = 'link',
    size = 'md',
    reference,
    ...otherProps
  } = props

  const [isSelected, setIsSelected] = useState(false)
  const { properties, dispatch } = useFavorites()

  const styles = useMemo(
    () => favoriteToggle({ variant, size }),
    [variant, size]
  )

  useEffect(() => {
    const selected = properties.some((property) => property === reference)

    setIsSelected(selected)
  }, [properties, reference])

  const onChange = (selected: boolean) => {
    dispatch({
      type: selected ? FavoriteAction.ADD : FavoriteAction.REMOVE,
      payload: reference
    })
  }

  return {
    className,
    styles,
    reference,
    componentProps: {
      ...otherProps,
      isSelected,
      onChange
    }
  }
}
