import { Button } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import { useButtonGroup, useButtonGroupProps } from './use-button-group'

export type ButtonGroupProps = useButtonGroupProps

const ButtonGroup = (props: ButtonGroupProps) => {
  const { className, styles, buttons } = useButtonGroup(props)

  if (buttons.length === 0) return null

  return (
    <ul className={styles.base({ className })}>
      {buttons.map((button, i) => (
        <li key={`button-${i}`} className={styles.item()}>
          {'href' in button ? (
            <Link {...button} />
          ) : (
            <Button key={`button-${i}`} {...button} />
          )}
        </li>
      ))}
    </ul>
  )
}

export default ButtonGroup
