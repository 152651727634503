'use client'

import {
  type Dispatch,
  type PropsWithChildren,
  createContext,
  useEffect,
  useReducer
} from 'react'
import type { ActionMap } from '@/types'
import { FAVORITES_STORAGE_KEY } from '@/config/favorites'
import { isBrowser } from '@/utils/env'

export enum FavoriteAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  CLEAR = 'CLEAR'
}

export type FavoritesPayload = {
  [FavoriteAction.ADD]: string
  [FavoriteAction.REMOVE]: string
  [FavoriteAction.CLEAR]: undefined
}

export type FavoritesActions =
  ActionMap<FavoritesPayload>[keyof ActionMap<FavoritesPayload>]

export type FavoritesState = string[]

export const initialFavoritesState = []

export const favoritesReducer = (
  state: FavoritesState,
  action: FavoritesActions
) => {
  switch (action.type) {
    case FavoriteAction.ADD:
      return [...state, action.payload]
    case FavoriteAction.REMOVE:
      return [...state.filter((reference) => reference !== action.payload)]
    case FavoriteAction.CLEAR:
      return [...initialFavoritesState]
    default:
      return state
  }
}

export const FavoritesContext = createContext<{
  state: FavoritesState
  dispatch: Dispatch<FavoritesActions>
}>({
  state: initialFavoritesState,
  dispatch: () => {}
})

const initializer = (initialValue = initialFavoritesState) => {
  if (!isBrowser) return initialValue

  const data = localStorage.getItem(FAVORITES_STORAGE_KEY)

  if (!data) {
    return initialValue
  }

  return JSON.parse(data)
}

export const FavoritesProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    favoritesReducer,
    initialFavoritesState,
    initializer
  )

  useEffect(() => {
    if (isBrowser) {
      const encodedState = JSON.stringify(state)
      localStorage.setItem(FAVORITES_STORAGE_KEY, encodedState)
    }
  }, [state])

  return (
    <FavoritesContext.Provider value={{ state, dispatch }}>
      {children}
    </FavoritesContext.Provider>
  )
}
