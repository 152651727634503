import { useMemo } from 'react'
import { ButtonProps } from '@/components/ui/button'
import { LinkProps } from '@/components/ui/link'
import { ButtonGroupVariantProps, buttonGroup } from './button-group.variants'

export type useButtonGroupProps = Omit<
  ButtonGroupVariantProps,
  'isDisabled'
> & {
  className?: string
  buttons: (ButtonProps | LinkProps)[]
}

export const useButtonGroup = (props: useButtonGroupProps) => {
  const {
    className,
    spacing = 'sm',
    direction = 'horizontal',
    align = 'start',
    buttons = []
  } = props

  const styles = useMemo(
    () => buttonGroup({ align, spacing, direction }),
    [align, spacing, direction]
  )

  return {
    styles,
    className,
    buttons
  }
}
