import { tv, VariantProps } from '@/utils/tv'

export const favoriteToggle = tv({
  slots: {
    base: [
      'flex justify-center items-center',
      'text-gray-400',
      'bg-transparent',
      'appearance-none',
      'text-center',
      'm-0 p-0',
      'transition-[color,background-color,border-color,transform] duration-300',
      'outline-none',
      'data-[pressed=true]:scale-[0.98]',
      'data-[disabled=true]:opacity-70',
      'data-[disabled=true]:cursor-not-allowed'
    ],
    icon: []
  },
  variants: {
    size: {
      sm: {
        icon: ['w-4 h-4']
      },
      md: {
        icon: ['w-5 h-5']
      },
      lg: {
        icon: ['w-6 h-6']
      }
    },
    variant: {
      link: {
        base: [
          'rounded-sm',
          'data-[selected=true]:text-green-500',
          'data-[focus-visible=true]:outline-dotted',
          'data-[focus-visible=true]:outline-offset-2',
          'data-[focus-visible=true]:outline-gray-100'
        ]
      },
      outline: {
        base: [
          'border border-gray-100',
          'rounded-md',
          'bg-white',
          'data-[hovered=true]:bg-green-500 data-[selected=true]:bg-green-500',
          'data-[hovered=true]:border-green-500 data-[selected=true]:border-green-500',
          'data-[hovered=true]:text-white data-[selected=true]:text-white',
          'data-[focus-visible=true]:outline-gray-200/30',
          'data-[focus-visible=true]:outline-offset-0',
          'data-[focus-visible=true]:outline-3'
        ]
      }
    }
  },
  compoundVariants: [
    {
      size: 'sm',
      variant: 'outline',
      className: {
        base: ['w-10 h-10']
      }
    },
    {
      size: 'md',
      variant: 'outline',
      className: {
        base: ['w-11 h-11']
      }
    },
    {
      size: 'lg',
      variant: 'outline',
      className: {
        base: ['w-12 h-12']
      }
    }
  ],
  defaultVariants: {
    variant: 'link',
    size: 'sm'
  }
})

export type FavoriteToggleVariantProps = VariantProps<typeof favoriteToggle>
