import { isDev } from '@/utils/env'

const normalizeSrc = (src: string) => {
  if (src.startsWith('/')) {
    return `https://execut.com.br/${src}`
  }

  return src.startsWith('/') ? src.slice(1) : src
}

export default function cloudflareLoader({ src, width, quality = 90 }: any) {
  if (isDev) return src

  const params = ['format=auto']

  if (quality) {
    params.push(`quality=${quality}`)
  }

  if (width) {
    params.push(`width=${width}`)
  }

  return `https://media.execut.cloud/${params.join(',')}/${normalizeSrc(src)}`
}
