import { useContext, useMemo } from 'react'
import { FavoritesContext } from '@/contexts/favorites'

export const useFavorites = () => {
  const { state, dispatch } = useContext(FavoritesContext)

  return useMemo(() => {
    return {
      properties: state,
      dispatch
    }
  }, [state, dispatch])
}
