import { tv, VariantProps } from '@/utils/tv'

export const skeleton = tv({
  slots: {
    base: [
      'relative',
      'overflow-hidden',
      'w-full',
      'bg-gray-50',
      'before:opacity-100',
      'before:absolute',
      'before:inset-0',
      'before:-translate-x-full',
      'before:animate-[shimmer_2s_infinite]',
      'before:border-t',
      'before:border-gray-100/60',
      'before:bg-gradient-to-r',
      'before:from-transparent',
      'before:via-gray-100',
      'before:to-transparent',
      'after:opacity-100',
      'after:absolute',
      'after:inset-0',
      'after:-z-10',
      'after:bg-gray-50'
    ],
    content: ['opacity-0']
  },
  variants: {
    isLoaded: {
      false: {
        base: 'transition-background before:transition-opacity duration-300 before:duration-300',
        content: 'transition-opacity motion-reduce:transition-none'
      },
      true: {
        base: 'bg-transparent before:opacity-0 before:animate-none after:opacity-0 duration-0 before:duration-0',
        content: 'opacity-100 duration-0 '
      }
    },
    rounded: {
      none: {},
      sm: {},
      md: {},
      full: {}
    }
  },
  compoundVariants: [
    {
      isLoaded: false,
      rounded: 'sm',
      class: {
        base: 'rounded-sm'
      }
    },
    {
      isLoaded: false,
      rounded: 'md',
      class: {
        base: 'rounded-md'
      }
    },
    {
      isLoaded: false,
      rounded: 'full',
      class: {
        base: 'rounded-full'
      }
    }
  ]
})

export type SkeletonVariantProps = VariantProps<typeof skeleton>
