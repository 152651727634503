'use client'

import { ToggleButton } from 'react-aria-components'
import { Vector } from '@/components/ui/vector'
import {
  useFavoriteToggle,
  useFavoriteToggleProps
} from './use-favorite-toggle'

type FavoriteToggleProps = useFavoriteToggleProps

const FavoriteToggle = (props: FavoriteToggleProps) => {
  const { className, styles, componentProps } = useFavoriteToggle(props)

  return (
    <ToggleButton {...componentProps} className={styles.base({ className })}>
      {({ isSelected }) => (
        <Vector
          name={!isSelected ? 'favorite' : 'favorite-solid'}
          className={styles.icon()}
        />
      )}
    </ToggleButton>
  )
}

export default FavoriteToggle
