'use client'

import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useState
} from 'react'
import { Property } from '@/types'

export type ConversionStateProps = {
  reference: Property['reference'] | null
  opened: boolean
}

type ConversionContextProps = {
  state: ConversionStateProps
  setState: Dispatch<SetStateAction<ConversionStateProps>>
}

const initialConversionState: ConversionStateProps = {
  reference: null,
  opened: false
}

export const ConversionContext = createContext<ConversionContextProps>({
  state: initialConversionState,
  setState: () => {}
})

export const ConversionProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState(initialConversionState)

  return (
    <ConversionContext.Provider value={{ state, setState }}>
      {children}
    </ConversionContext.Provider>
  )
}
